<template>
  <!-- SECTION modal-modify-remarks -->
  <b-modal
    id="modal-modify-remarks"
    :title="$t('reservation.remarks.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    size="lg"
    @show="handleShow"
  >
    <BFormGroup
      label-for="remarks-input"
    >
      <template #label>
        <div class="text-nowrap">
          {{ $t('reservation.remarks.title') }}
        </div>
      </template>
      <b-form-input
        id="remarks-input"
        v-model="remarksContent"
        style="font-size: 16px; line-height: 24px;"
        lazy-formatter
        :formatter="trimInput"
      />
    </BFormGroup>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient mt-lg-25  border-0"
        :disabled="!remarksContent"
        pill
        @click="sendRemarksHandle"
      >
        <span class="align-middle">{{ $t('reservation.remarks.send') }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import {
  ref, toRefs,
} from '@vue/composition-api'

import { trimInput } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal, BButton, BFormInput, BFormGroup,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { modifyRemarks } = useReservationHandle()
    const bookingData = toRefs(props).bookingData
    const remarksContent = ref('')

    function sendRemarksHandle() {
      this.$bvModal.show('modal-api-loading')
      const payload = {
        source: bookingData.value.source,
        airline: bookingData.value.airlines.split(' | ')[0] || bookingData.value.platingAirline || 'VN',
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code || '',
        remarkUpdates: [{
          type: 'HS',
          text: remarksContent.value,
          operation: 'ADD',
          id: '7',
        }],
        pnrNumber: bookingData.value.bookingCode,
        operation: 'ADD',
      }
      modifyRemarks(payload)
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
          this.$bvModal.hide('modal-modify-remarks')
        })
    }

    function handleShow() {
      remarksContent.value = ''
    }

    return {
      sendRemarksHandle,
      remarksContent,
      trimInput,
      handleShow,
    }
  },
}
</script>
